import React from 'react';
import SEO from '../components/SEO';
import { ImageGrid, ImageCaption } from '../styles/ImageGrid';
import { TripHeader } from '../components/TripHeader';
import Mwjerinwinterpatrickatcamp from '../assets/images/mwjerinwinterpatrickatcamp.jpg';
import Baileymwjwinterpatrickincaddy from '../assets/images/baileymwjwinterpatrickincaddy.jpg';
import Baileychillin from '../assets/images/baileychillin.jpg';
import Mwjwinterbaileyathhroadsign from '../assets/images/mwjwinterbaileyathhroadsign.jpg';
import Patrickerinwintermwjatcrackerbarrel from '../assets/images/patrickerinwintermwjatcrackerbarrel.jpg';
import Gorrila from '../assets/images/gorrila.jpg';

export default function Page() {
  return (
    <>
      <SEO title="Moultrie Tale" />
      <div>
        <TripHeader>
          <h2>The Moultrie Tale</h2>
          <p>By: Erin Jordan</p>
          <p>Some text provided by: Michael Wayne Jordan</p>
          <img
            src={Mwjerinwinterpatrickatcamp}
            alt="Michael Wayne, Erin, Winter & Patrick at Campsite"
          />
          <ImageCaption>
            Michael Wayne, Erin, Winter & Patrick at Campsite
          </ImageCaption>
        </TripHeader>
        <p>
          If you have never driven to Moultrie, Georgia in a 1985 model Cadillac
          playing Nintendo 64 on a five-inch television screen that is suspended
          in air by two bungee cords, then you have not lived. As absurd as it
          sounds, the above statement is very true. It all happened one weekend
          in February 2001, when a few of my friends and I decided to take a
          road trip to an automotive swap meet in Moultrie, Georgia.
        </p>
        <p>
          On Friday, February 2, 2001 at 11:30 A.M., Winter Hicks, Bailey McKay,
          and I checked out of Clay County High School to meet up with Patrick
          Sobers and my brother Michael Wayne Jordan to begin our long trek to
          the world renown swap meet in a lonesome little city in southern
          Georgia called Moultrie. After a short delay due to transmission
          difficulties in the "Caddy", Michael Wayne, Patrick, and I departed
          our house and made our way over to Winter's house. While Winter and I
          carefully crammed over two hundred pounds of luggage into the
          Cadillac's trunk, the guys were busy adding the finishing touches to
          the vast array of electronic gadgetry. These gadgets included a DVD
          player, a Nintendo 64 with four controllers, a five-inch color
          television, three cellular phones, a laptop computer, three
          walkie-talkies, an inverter to supply household current, and several
          hundred yards of wire. After everyone was set, we piled into our own
          Circuit City on wheels, and made our way down the lonesome highway to
          Georgia.
        </p>
        <p>
          After making our way through two movies, we found ourselves longing
          for nourishment. Soon, the question arose concerning where to eat. The
          feminine corner of the car quickly made the request for waitresses,
          while the virile corner would have been satisfied with a fast food
          drive-through. As usual, the ladies won, but the only feasible option
          left was Denny's. Upon entering the restaurant, we were directed to
          our table by a waitress with rather strange hair. During the process
          of distributing our drinks, she accidentally dropped an extra glass of
          tea, which emptied its contents onto Bailey before shattering on the
          floor. This turn of events prompted Bailey to glare at the girls and
          say, "You wanted waitresses; you've got waitresses."
        </p>
        <ImageGrid>
          <div>
            <img
              src={Baileymwjwinterpatrickincaddy}
              alt="Bailey, Michael Wayne, Winter & Patrick in Caddy"
            />
          </div>
          <div>
            <img src={Baileychillin} alt="Bailey Chillin" />
          </div>
          <div>
            <img
              src={Mwjwinterbaileyathhroadsign}
              alt="Michael Wayne, Winter & Bailey at the Huddle House Road Sign"
            />
          </div>
        </ImageGrid>
        <p>
          We arrived in Moultrie at approximately 10:00 P.M. Central time. After
          driving to the campsite and meeting up with my father, Winter and I
          found that we were in great need of the facilities. Seeing that there
          was not a bathroom within a five-mile radius, the five of us piled
          back into the Cadillac and made our way over to the local Wal-mart
          Super center. There, we used the bathroom, and finally made our few
          purchases after being delayed almost an hour by some foreigners in
          line in front of us who were somewhat befuddled over the price of a
          few peppers. After the Wal-mart delay, we go back to the campsite to
          survey our weekend home. After much confusion about where to sleep,
          Winter and I crawl into the upper bunk of our house on wheels, while
          Michael Wayne takes over the bottom bunk. Patrick and Bailey were
          voted out of the camper. While Bailey continued to play Nintendo in
          the "Caddy" and begin to go to bed, Patrick decides that although
          riding in the Cadillac might be fun, sleeping in it was a different
          story. He proceeds to drag his supposed "two-man" tent out of the
          trunk and put it up in the dark, by himself. This act in itself seemed
          to be rather amusing to Winter and I as we watched from the warm
          camper.
        </p>
        <p>
          We awakened in the morning to the sounds of a campsite. Actually, I
          awakened to the sound of a woman in the adjoining campsite talking
          rather loudly on the phone, and what seemed like a small, rabid dog
          attacking several tiny rodents. The orange Dooley seemed a bit out of
          place amongst the rather costly, new R.V.'s that surrounded it. Upon
          waking up, we gathered our belongings and piled back into the Cadillac
          and headed over to Tommy Hicks' motel room to shower. After we each
          had showered, we made our way over to the car show where we were
          entertained greatly by the immense array of automotive machinery on
          display. At the swap meet, a vendor selling a large variety of patches
          tickles the fancy of young Mr. Bailey McKay. Since he has been
          searching for patches to go on his newly acquired "Huddle House Fan
          Club" jacket, he purchased several of them varying from "Spock lives"
          to "Volkswagen". This prompted Michael Wayne and I to buy our own
          patches for our similar denim jackets. Also at the swap meet, we
          entertained ourselves by using Bailey's digital camera to make several
          amusing pictures.
        </p>
        <p>
          That night, we joined Winter's father at the local Western Sizzlin' to
          eat supper. Afterwards, we went over to his motel room to engage in
          some friendly chatter. Seeing that even after all of this it was only
          8:00, we went back to the camper where the girls outwitted the guys in
          a heated game of Bible trivia. After all the excitement, Winter felt
          the need to once again use the restroom (due, in part to our addiction
          to Cran-Grape the whole weekend). Patrick and I quickly added that we
          were definitely too fatigued to travel anywhere, so we declined the
          offer to join them in a journey to the local Huddle House. Bailey,
          being excited at the thought of going to yet another Huddle House,
          immediately joined Winter in a quick dance in the rain, (something
          that she had always wanted to do). Michael Wayne, Winter, and Bailey
          headed over to the Huddle House where they met Honey, the nicest
          waitress ever. Bailey told Honey of his infatuation with Huddle
          Houses, which she thought, was just amazing. After learning of their
          love of the Huddle House, Honey gave each of them an authentic Huddle
          House ashtray, as well as a very classic Huddle House key-chain. After
          they bought their food and said a very hard goodbye to Honey, the trio
          departed the Huddle House in order to take a picture in front of the
          enormous Huddle House billboard, which overlooked the city of
          Moultrie. Since Bailey and Patrick complained all day about their
          uncomfortable sleeping quarters, Michael Wayne, being the good hearted
          fellow that he is, allowed the two babies to take over his spot in the
          camper. This arrangement did not bother Michael Wayne in the least
          since this allowed him to play Rush 2 on the Nintendo well into the
          night. This marked the end of the second day.
        </p>
        <ImageGrid>
          <div>
            <img
              src={Patrickerinwintermwjatcrackerbarrel}
              alt="Patrick, Erin, Winter & Michael Wayne at Cracker Barrel"
            />
          </div>
          <div>
            <img src={Gorrila} alt="Gorilla" />
          </div>
        </ImageGrid>
        <p>
          Sunday morning found the crew of five cramming everything back into
          the trunk of the Cadillac. This was a very historic moment in the
          Huddle House adventures, for on the trip back home we would stop at
          every Huddle House we could find. In order to hit as many Huddle
          Houses as possible, we decided to make some strategic changes in our
          return course. Since Huddle Houses are usually situated adjacent to
          larger thoroughfares, we opted to travel north on I-75 to Atlanta.
          This proved to be quite beneficial since we saw many more on the way
          home than on the trip to Moultrie. Our only regret was not being able
          to visit with the employees and regulars of each Huddle House on the
          way due to our constrained schedule. Nevertheless, we persevered
          towards our goal and the charming allure of our home county. We
          arrived in Ashland at approximately 4:15 P.M., worn out yet jubilant
          over the completion of our quest. We let Bailey out at his humble
          abode, and made our way over to the Hicks' residence where we unloaded
          the Cadillac. Even after we were done, we stood around in the yard
          reminiscing about our action packed weekend.
        </p>
        <p>
          To the common, everyday man this trip might have seemed like nothing
          but a waste of time and fossil fuels, but to five friends in Clay
          County Alabama, this trip was the kind of adventure that you would
          tell your grandkids about. Cynics might claim that the younger
          generation only wants to party and use controlled substances, but our
          experience proves them wrong. We had a "high" time being with each
          other while we took the "trip" of our lives!
        </p>
      </div>
    </>
  );
}
